#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000000;
}

@media only screen and (max-width: 991px) {
  #getStartedText {
    display: block;
  }

  #getStartedButton {
    display: none;
  }
  
  #bigNav {
    display: none; 
  }

  #mobileNav {
    display: block;
  }
  /* #navLinks {
    padding-right: 10px;
  } */
}

@media only screen and (min-width: 992px) {
  #getStartedText {
    display: none;
  }

  #getStartedButton {
    display: block;
  }

  #bigNav {
    display: block; 
  }

  #mobileNav {
    display: none;
  }
}




