.project:hover {
    box-shadow: 0px 20px 20px 0 rgba(100, 0, 0, 0.2) !important;
}
#bg {
    box-shadow: 0px 20px 20px 0 rgba(100, 0, 0, 0.2) !important;
}



@media only screen and (max-width: 959px) {
    #content {
        /* width: 374px; */
        height: 100%;
        width: 100%;
          color: white;
         background-image: url('../../../images/Personal/LMS.png');
        background-position: center;
        background-size: cover;
    }

    #featuredImage {
        background-color: rgb(30, 0, 0, .7);
        padding: 40px 20px;   
                height: 100%;
        width: 100%;

    }
    #description {
        background-color: transparent !important;
        padding: 0px !important;
    }

    #root {
        
    }
    
    #bg {
      display: none;
        /* margin-right: -100px;
        height: 273px;
        width: 438px; */
    }
}



@media only screen and (max-width: 1299px) {
  #hideName {
      display: none;
  }
 }
 .stretch_it{ white-space: nowrap; }
.justify{ text-align:justify; }
