

#listItem {
    background-size: 200% 100%;
    background-image: linear-gradient(to right, #888 50%,  red 0);
    transition: background-position 1s;
    
}

#listItem:hover {
    background-position: -100% 0%;
}

.listItem2 {
    cursor: pointer;
}

@media only screen and (max-width: 959px) {
   .listItem {
    display: flex;
    overflow-x: auto;
    width: 100% !important;
   }
   .listItem2 {
        height: 50px !important;
        white-space: nowrap;
   }
   .hideme {
       display: none;
   }
}