/* #service:hover{
    border-radius: 5px;
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
} */

/* #services {
    background-color: white;
    margin: 0;
}

#services:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -250px;
    width: 750px;

    background: #000;
    transform: skew(-30deg);
  } */

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
        #servicesOverlay {
        background-color: rgba(24, 44, 109, 0.89)!important;
        }
    }