@media only screen and (max-width: 959px) {
    #container {
        margin-top: 20px;
    }
    #left {
        text-align: left;
    }
  }
@media only screen and (min-width: 960px) {
    #left {
        text-align: center;
    }
  }