#lmsImage {
    height: 500px;
    width: 500px;
}

@media only screen and (max-width: 533px) {

    #lmsImage {
        width: 100%;
        height: 100%;
    }
}