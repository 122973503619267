html {
  scroll-behavior: smooth;
  
}
/* aliceblue, snow, gansboro */


body {
  font-family: Montserrat, -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: snow;
}
h1 {
  font-size: 46px;
  font-weight: 700;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0px;
}
h3 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
}
h4 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
}

p {
  font-family:  Montserrat, -apple-system, sans-serif;
  color: 'black';
  font-size: 18px;
  font-weight: 400;
}

#alternateBackgroundColor {
  background-color: #eff2f6;
}

#section {
  padding-top: 75px;
  padding-bottom: 75px;
}

@media only screen and (max-width: 500px) {
  .scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 450px;
  }
}
.section {
  padding-top: 75px;
  padding-bottom: 75px;
}