.image{
    border-radius: 5px;
    box-shadow: 8px 20px 12px 0 rgba(100,0,0,0.2);
    width: 80%;
}

@media only screen and (max-width: 592px) {
    .image {
        width: 100%;
    }
}
