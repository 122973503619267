#image {
    background-image: url('../../../images/LMS/Wallpaper.jpg');
    height: 100%;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    background-position: center;
    background-size: cover;
    z-index: -1;
    position: fixed;
}

#form {
    position: absolute;
    width: 600px;
    right: 0;
    background-color: white;
    z-index: 1;
    min-height: 100vh;

}

@media only screen and (max-width: 600px) {
    #form {
        width: 100%;
    }
}
