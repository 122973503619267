#bounce {
    animation: bounce 2s infinite linear;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
    }
}

/* 1564 */

@media only screen and (max-width: 437px) {
    #line {
        visibility: hidden;
    }
}

@media only screen and (max-width: 1100px) {
    .text {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

@media only screen and (min-width: 1101px) {
    .text {
        margin-left: auto;
        width: 50%;
    }
}